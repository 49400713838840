<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
            <div class="page-header-left pl-3 pt-5">
                <h3>Tài khoản: {{currentItem.Username}}</h3>
            </div>
            <v-layout row wrap justify-end>
              <v-flex class="lg3">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>Tiền nạp</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{currentItem.TotalAmount | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex class="lg3">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>Tiền hàng</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{currentItem.TotalPayment | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex class="lg3">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>Dư ví</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{currentItem.Wallet | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex class="lg3">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>Công nợ</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{currentItem.Debt | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg12>
            <div class="page-header-left pl-3 pt-5">
                <h3>Phiếu xuất kho</h3>
            </div>
            <export-receipt-list :uId="uId"></export-receipt-list>
        </v-flex>
        <v-flex lg12>
            <div class="page-header-left pl-3 pt-5">
                <h3>Lịch sử nạp tiền</h3>
            </div>
            <v-data-table  :headers="recharge_headers"  :items="recharges" :options.sync="recharge_pagination" :server-items-length="recharge_rows" :footer-props="items_per_page" :must-sort="true" :loading="recharge_loading" :mobile-breakpoint="100">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>
                            {{ item.CreatedDate | display_date('DD-MM-YYYY')}}
                            <br />
                            {{ item.CreatedDate | display_date('HH:mm:ss')}}
                        </td>
                        <td v-bind:style="{ 'color': get_status_color(item.Status, recharge_status_list)}">{{ item.Status | display_value(recharge_status_list) }}</td>
                        <td>{{ item.Method | display_value(recharge_methods) }}</td>
                        <td class="text-end">{{ item.Amount | currency }}</td>
                    </tr>
                </template>
                <template v-slot:body.append>
                    <tr class="total-summary-td">
                        <td colspan="3" class="text-center font-weight-bold">Tổng</td>
                        <td class="text-end font-weight-bold">{{ !recharge_summary ? 0 : recharge_summary.Amount | currency }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
        <v-flex lg12>
            <div class="page-header-left pl-3 pt-5">
                <h3>Lịch sử rút tiền</h3>
            </div>
            <v-data-table  :headers="withdraw_headers"  :items="withdraws" :options.sync="withdraw_pagination" :server-items-length="withdraw_rows" :footer-props="items_per_page" :must-sort="true" :loading="withdraw_loading" :mobile-breakpoint="100">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>
                            {{ item.CreatedDate | display_date('DD-MM-YYYY')}}
                            <br />
                            {{ item.CreatedDate | display_date('HH:mm:ss')}}
                        </td>
                        <td v-bind:style="{ 'color': get_status_color(item.Status, withdraw_status_list)}">{{ item.Status | display_value(withdraw_status_list) }}</td>
                        <td class="text-end">{{ item.Amount | currency }}</td>
                    </tr>
                </template>
                <template v-slot:body.append>
                    <tr class="total-summary-td">
                        <td colspan="2" class="text-center font-weight-bold">Tổng</td>
                        <td class="text-end font-weight-bold">{{ !withdraw_summary ? 0 : withdraw_summary.Amount | currency }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import ExportReceiptList from "@/views/ExportReceipt/list";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
export default {
  components: {
    "export-receipt-list": ExportReceiptList
  },
  data() {
    return {
      valid: true,
      payservice_statuslist: configs.PAY_SERVICE_STATUS,
      recharge_status_list: configs.RECHARGE_STATUS_LIST,
      recharge_methods: configs.RECHARGE_METHODS,
      withdraw_status_list: configs.WITHDRAW_STATUS_LIST,
      payment_type: configs.PAYMENT_STATUS_LIST,
      payment_type_enum: configs.PAYMENT_STATUS,
      recharge_headers: [
        {
          text: "Ngày nạp",
          value: "CreatedDate"
        },
        {
          text: "Trạng thái",
          value: "Status"
        },
        {
          text: "Hình thức",
          value: "Method"
        },
        {
          text: "Số tiền",
          value: "TotalAmount",
          align: "end",
        }
      ],
      recharge_pagination: {
          ...configs.PAGINATION,
          sortBy: ["CreatedDate"]
      },
      withdraw_headers: [
        {
          text: "Ngày rút",
          value: "CreatedDate"
        },
        {
          text: "Trạng thái",
          value: "Status"
        },
        {
          text: "Số tiền",
          value: "TotalAmount",
          align: "end",
        }
      ],
      withdraw_pagination: {
          ...configs.PAGINATION,
          sortBy: ["CreatedDate"]
      },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      filterConditions: [],
      quickFilterConditions: [],
      viewName: "bao-cao-cong-no-chi-tiet",
      summary: {}
    };
  },
  watch: {
    recharge_pagination: {
        handler: function (val, oldVal) {
            let filterConditions = this.$store.state.commons.filterConditions;
            this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
            this.recharge_filter_data();
        },
        deep: true
    },
    withdraw_pagination: {
        handler: function (val, oldVal) {
            let filterConditions = this.$store.state.commons.filterConditions;
            this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
            this.withdraw_filter_data();
        },
        deep: true
    },
  },
  computed: {
    ...mapState({
        currentItem: state => state.report.debtReportDetail,
        recharges: state => state.rechargeHistory.all.data,
        recharge_rows: state => state.rechargeHistory.all.total,
        recharge_summary: state => state.rechargeHistory.all.summary,
        recharge_loading: state => state.rechargeHistory.loading,
        withdraws: state => state.withDraw.all.data,
        withdraw_rows: state => state.withDraw.all.total,
        withdraw_summary: state => state.withDraw.all.summary,
        withdraw_loading: state => state.withDraw.loading,
    }),
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    },
    uId() {
        return Number(this.$route.params.Pid);
    },
  },
  methods: {
    filter_data: function() {
        this.$store.dispatch("report/getDebtReportDetail", this.uId);
        this.recharge_filter_data();
        this.withdraw_filter_data();
    },
    recharge_filter_data: function() {
      var param = {
        pagination: this.recharge_pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      param.filter = [
        {
            column: 'Status',
            value: 2,
            condition: 'equal'
        },
        {
            column: 'UID',
            value: this.uId,
            condition: 'equal'
        }
      ]
      this.$store.dispatch("rechargeHistory/getList", param);
    },
    withdraw_filter_data: function() {
      var param = {
        pagination: this.withdraw_pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      param.filter = [
        {
            column: 'Status',
            value: 2,
            condition: 'equal'
        },
        {
            column: 'UID',
            value: this.uId,
            condition: 'equal'
        }
      ]
      this.$store.dispatch("withDraw/getList", param);
    },
    get_status_color(val, enums) {
      return this.$options.filters.display_color(val, enums);
    },
    get_order_status_color(value){
      const objStatus = configs.ORDER_STATUS.find(obj => obj.value === value);
      return objStatus ? objStatus.color : 'black';
    }
  },
  mounted() {
    this.filter_data();
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
  }
};
</script>